import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import "./Announcement.scss";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import No_Data_Found from "../../../assets/icons/No_Data_Found.svg";
import Pdf from "react-to-pdf";
import moment from "moment";
import {
  getAllAnnouncementAsync,
  updateAnnouncementAsync,
} from "../../../redux/announcementsSlice";
import Loader from "../../../components/Loader";
import { Tooltip } from "react-tooltip";
function Announcement() {
  const dispatch = useDispatch();
  const [serachAnnouncement, setSearchAnnouncement] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [editorTitle, setEditorTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const ref = useRef();

  const { announcementData, updateAnnouncementLoading, announcementLoading } =
    useSelector((state) => state.announcement);

  useEffect(() => {
    dispatch(getAllAnnouncementAsync("all"));
  }, [dispatch]);

  const fontOptions = [
    { value: "sans-serif", label: "Sans Serif" },
    { value: "serif", label: "Serif" },
    { value: "monospace", label: "Monospace" },
    { value: "arial", label: "Arial" },
    { value: "times-new-roman", label: "Times New Roman" },
    { value: "courier", label: "Courier" },
  ];

  const modules = {
    toolbar: [
      [{ font: fontOptions.map((option) => option.value) }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      // [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const handleEdit = (announcement) => {
    setSelectedAnnouncement(announcement);
    setEditorTitle(announcement.title);
    setEditorContent(announcement.description);
    setIsEditing(true);
  };

  const handleSave = () => {
    if (!selectedAnnouncement) return;
    const updatedAnnouncement = {
      id: selectedAnnouncement.id,
      title: editorTitle,
      description: editorContent,
    };
    dispatch(updateAnnouncementAsync(updatedAnnouncement)).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setIsEditing(false);
        setSelectedAnnouncement(null);
        dispatch(getAllAnnouncementAsync("all"));
      }
    });
  };

  const SearchAnnouncementByQuery = (e) => {
    setSearchAnnouncement(e.target.value);
  };

  const SeacrhQueryRequestF_APICall = () => {
    if (serachAnnouncement.trim()?.length > 0) {
      dispatch(getAllAnnouncementAsync(serachAnnouncement.trim()));
    } else {
      dispatch(getAllAnnouncementAsync("all"));
    }
  };

  const pagePrint = () => {
    window.print();
  };

  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));
    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD/MM/YYYY");
    }
  };
  return (
    <ContainerWithoutFooter>
      {updateAnnouncementLoading || announcementLoading ? <Loader /> : null}
      <div className="topBar">
        <h1>Announcements Text</h1>
        {!isEditing && (
          <div className="rightIconContainer">
            <div className="search-area">
              <div className="p-1 SearchIcon">
                <SearchIcon
                  onClick={SeacrhQueryRequestF_APICall}
                  style={{ color: "#777", cursor: "pointer" }}
                />
              </div>
              <input
                onChange={SearchAnnouncementByQuery}
                type="text"
                value={serachAnnouncement}
                placeholder="Search like this IPT_ID"
                onKeyDown={(event) => {
                  event.key === "Enter" && SeacrhQueryRequestF_APICall();
                }}
              />
            </div>
            <div className="pdf">
              <PictureAsPdfOutlinedIcon className="Icons" />
              <Pdf
                targetRef={ref}
                filename="document.pdf"
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => <p onClick={toPdf}>Save as pdf</p>}
              </Pdf>
            </div>
            <div onClick={pagePrint} className="pdf2">
              <LocalPrintshopOutlinedIcon className="Icons" />
              <p>Print</p>
            </div>
          </div>
        )}
      </div>

      <div ref={ref} className="inner-container-Announcement">
        {isEditing ? (
          <div className="editor-container">
            <div className="Heading_Edit_btn">
              <input
                type="text"
                value={editorTitle}
                onChange={(e) => setEditorTitle(e.target.value)}
                className="title-input"
                placeholder="Enter announcement title"
              />
              <div className="action-buttons">
                <div onClick={() => setIsEditing(false)} className="cancel-btn">
                  <p>Cancel</p>
                </div>
                <div onClick={handleSave} className="save-btn">
                  <p>Save</p>
                </div>
              </div>
            </div>
            <ReactQuill
              value={editorContent}
              onChange={setEditorContent}
              modules={modules}
              formats={[
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "align",
                "list",
                "bullet",
                "indent",
                "color",
                "background"
              ]}
            />
          </div>
        ) : (
          <div className="CardContainer">
            {announcementData?.length !== 0 ? (
              <>
                {announcementData?.map((item) => (
                  <div className="cards" key={item?.id}>
                    <div className="topitems">
                      {item?.title?.length > 21 ? (
                        <>
                          <p
                            className="itemText"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={item?.title}
                          >
                            {item?.title?.length > 5
                              ? item?.title?.slice(0, 21) + "..."
                              : item?.title}
                          </p>
                          {item?.title?.length > 5 && (
                            <Tooltip
                              id="my-tooltip"
                              style={{
                                backgroundColor: "#38ab7c",
                                zIndex: 100,
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <div className="itemText">{item?.title}</div>
                      )}
                      <div
                        className="itemIcon"
                        onClick={() => handleEdit(item)}
                      >
                        <DriveFileRenameOutlineOutlinedIcon className="editIcon" />
                      </div>
                    </div>
                    <div
                      className="editor-content ql-editor"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                    <p>{formatDate(item?.updatedAt * 1000)}</p>
                  </div>
                ))}
              </>
            ) : (
              <div className="NoDataFound">
                <div className="imageContainer">
                  <img src={No_Data_Found} alt="No Data Found" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </ContainerWithoutFooter>
  );
}

export default Announcement;
